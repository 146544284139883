<template>
  <div v-if="ready" class="grid w-full" v-bind:class="feedGridClass">
      <Feed v-for="(feed, index) in feeds.slice(0,totalFeeds)" :key="feed.id" :feed="feed" :index="index"/>
      <ChannelSelectModal :feeds="feeds.slice(0,totalFeeds)" />
      <ChannelInputModal />
  </div>
</template>

<script>
import FeedData from "@/assets/feeds.json";
import Feed from "./Feed.vue";
import ChannelSelectModal from "./ChannelSelect/Modal.vue";
import ChannelInputModal from "./ChannelInput/Modal.vue";

export default {
    data() {
        return {
            totalFeeds: 6,
            ready: false,
            feeds: JSON.parse(JSON.stringify(FeedData))
        }
    },
    computed: {
        feedGridClass: function() {
            return `feed-grid-${this.totalFeeds}`
        }
    },
    mounted() {
        window.onYouTubeIframeAPIReady = () => {
            this.ready = true;
        }

        this.emitter.on('change-channel', data => {
            this.feeds[data.index] = data.feed;
        })

        this.emitter.on('change-video-grid', value => {
            this.totalFeeds = value;
        })

        this.emitter.on('settings-reset', () => {
            this.feeds = JSON.parse(JSON.stringify(FeedData))
            this.totalFeeds = 6
        })

        if (localStorage.feeds) {
            this.feeds = JSON.parse(localStorage.feeds)
        }

        if (localStorage.videoGrid) {
            this.totalFeeds = localStorage.videoGrid
        }
    },
    watch: {
        feeds: {
            handler(update) {
                console.log('feeds updated', update);
                localStorage.feeds = JSON.stringify(update);
            },
            deep: true
        }
    },
    components: {
        Feed,
        ChannelSelectModal,
        ChannelInputModal
    }
};
</script>

<style lang="scss">
    // .feed-grid {
    //     width: 50%;
    //     display: inline-block;
    //     height: 56.25%;
    //     margin: 0 -1px;
    // }

    .feed-grid-6 {
        @apply grid-cols-1 md:grid-cols-2 lg:grid-cols-3;
    }

    .feed-grid-4, .feed-grid-2 {
        @apply grid-cols-1 md:grid-cols-2
    }

    .feed-grid-1 {
        @apply grid-cols-1
    }

</style>
