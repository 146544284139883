<template>
    <div class="video-wrapper relative">
        <Overlay @paused=pauseYoutube @open="openVideo" @mute="mutePlayer" @channel-select="channelSelect" @channel-input="channelInput" :playerState="playerState" :playerMuted="playerMuted" />
        <div :id="feed.id + '-' + index"></div>
    </div>
</template>

<script>
import Overlay from "./Overlay.vue"

export default {
    props: ['feed','index'],
    data: function() {
        return {
            player: null,
            playerState: true,
            playerMuted: true,
        }
    },
    mounted() {
        this.initYoutube();

        this.emitter.on('close-video', id => {
            if (id == this.feed.id) {
                this.player.playVideo();
            }
        })
    },
    methods: {
        initYoutube() {
            this.player = new window.YT.Player(this.feed.id + '-' + this.index, {
                width: "100%",
                height: "100%",
                videoId: this.feed.id,
                playerVars: { 'autoplay': 1, 'controls': 0, 'mute': 1},
                events: {
                    'onStateChange': () => {
                        if (this.player.getPlayerState() != 1) {
                            this.playerState = true;
                        } else {
                            this.playerState = false;
                        }
                    }
                }
            });
        },
        pauseYoutube() {
            if (this.player.getPlayerState() != 1) {
                this.player.playVideo();
            } else {
                this.player.pauseVideo();
            }
        },
        openVideo() {            
            this.emitter.emit('open-video', this.feed.id)
            this.player.stopVideo();
        },
        channelSelect() {
            this.emitter.emit('open-channel-select', {id: this.feed.id, index: this.index})
        },
        channelInput() {
            this.emitter.emit('open-channel-input', this.index)
        },
        checkMuteState() {
            if (this.player.isMuted()) {
                this.playerMuted = false;
            } else {
                this.playerMuted = true;
            }
        },
        mutePlayer() {
            if (this.playerState) {
                return
            }

            if (this.player.isMuted()) {
                this.player.unMute()
                this.playerMuted = false;
            } else {
                this.player.mute()
                this.playerMuted = true;
            }
        }
    },
    components: {
        Overlay,
    },
}
</script>

<style lang="scss">
    .video-wrapper {
        iframe {
            @apply w-full aspect-video lg:aspect-auto;
        }
    }
//  .video-wrapper {
//     width: 100%;
//     padding-bottom: 56.25%; 
//     padding-top: 30px;
//     overflow: hidden;
//     position: relative;

//      iframe {
//          position: absolute;
//          top: 0;
//          left: 0;
//          width: 100%;
//          height: 100%;
//      }
//  }
</style>