<template>
  <div>
    <div class="mb-3">Video Grid</div>
    <div class="flex justify-between mb-4 gap-2">
        <div v-for="size in gridSizes" @click="updateGrid(size)" class="button" v-bind:class="{'selected': isSelected(size)}" :key="size">{{size}}</div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            selected: 6,
            gridSizes: [1,2,4,6]
        }
    },
    mounted() {
        this.emitter.on('settings-reset', () => {
            this.selected = 6
        })

        if (localStorage.videoGrid) {
            this.selected = localStorage.videoGrid
        }
    },
    methods: {
        updateGrid(value) {
            this.emitter.emit('change-video-grid', value)
            this.selected = value;
        },
        isSelected(value) {
            if(this.selected == value) {
                return true;
            }

            return false;
        }
    },
    watch: {
        selected(value) {
            localStorage.videoGrid = value
        }
    }
}
</script>

<style lang="scss" scoped>
    .button {
        @apply cursor-pointer bg-yellow-400 rounded-md px-2;

        &.selected {
            @apply border-2 border-black border-solid;
        }
    }
</style>