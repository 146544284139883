import { createApp } from "vue";
import App from "./App.vue";
import mitt from 'mitt';
import Gleap from 'gleap';
import VueSocialSharing from 'vue-social-sharing'

import "./assets/tailwind.css";

Gleap.initialize("yk4o2TAFreeG19Lceb4knrF3H5NU3ivK");

const emitter = mitt();
const app = createApp(App)

app.use(VueSocialSharing)
app.config.globalProperties.emitter = emitter;
app.mount("#app");