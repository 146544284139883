<template>
  <div v-if="active" id="reddit-live">
    <iframe class="h-96 md:h-full w-full md:w-max" src="https://www.redditmedia.com/live/18hnzysb1elcs/embed" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false
    }
  },
  mounted() {
    this.emitter.on('toggle-reddit-panel', value => this.active = value)

    if (localStorage.redditLive) {
      console.log('redditlive', localStorage.redditLive)
      this.active = (localStorage.redditLive == "true")
    }
  }
}
</script>

<style>

</style>