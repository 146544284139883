<template>
    <div class="flex flex-col text-center">
            <label for="toggle" class="inline-flex items-center cursor-pointer">
                <span class="relative">
                <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                <span class="absolute block w-4 h-4 mt-1 ml-1 bg-white rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out" v-bind:class="{'enabled': reddit}">
                    <input @click="toggleReddit" id="toggle" type="checkbox" class="absolute opacity-0 w-0 h-0" />
                </span>
                </span>
                <span class="ml-3">Reddit</span>
            </label>
    </div>
</template>

<script>
export default {
    data() {
        return {
            reddit: false,
        }
    },
    mounted() {
        this.emitter.on('settings-reset', () => {
            this.reddit = false;
            this.emitter.emit('toggle-reddit-panel', false)
        })

        if(localStorage.redditLive) {
            this.reddit = (localStorage.redditLive == "true")
        }
    },
    methods: {
        toggleReddit() {
            this.reddit = !this.reddit
            this.emitter.emit('toggle-reddit-panel', this.reddit)
        },
    },
    watch: {
        reddit(value) {      
            localStorage.redditLive = value;
        } 
    }
}
</script>

<style lang="scss" scoped>
    .enabled {
        @apply transform translate-x-full bg-gray-800;
    }
</style>