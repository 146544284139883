<template>
    <div class="overlay absolute top-0 w-full h-full z-10 cursor-pointer" @mouseover="hover = true" @mouseleave="hover = false">

        <div v-if="!playerMuted || hover" class="button-group z-20 absolute right-0 p-2 m-2 rounded-full cursor-pointer">
            <div @click="$emit('mute') && (hover=false)" class="button">
                <svg v-if="!playerMuted" xmlns="http://www.w3.org/2000/svg" class="small" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="small" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clip-rule="evenodd" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
                </svg>
            </div>
        </div>
        
        <Transition>
            <div v-if="hover" class="controls flex items-center justify-center h-full" @click="close"> 
                    <div class="flex gap-8 p-2 rounded-2xl button-group">
                        <div @click="$emit('paused') && (hover=false)" class="button">
                            <svg v-if="!playerState" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <div class="button" @click="$emit('open') && (hover=false)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                        </div>
                        <div class="button" @click="$emit('channel-select') && (hover=false)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <div class="button" @click="$emit('channel-input') && (hover=false)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                    </div>
            </div>
        </Transition> 
    </div>

</template>

<script>
export default {
    props: ['playerState','playerMuted'],
    data: function() {
        return {
            hover: false,
            mouseLeft: true
        }
    },
    methods: {
        close() {
            if (window.innerWidth <= 1024) {
                this.hover = false
            }
        }
    }
}
</script>

<style lang="scss">


.controls {
    background-color: rgba(0,0,0,0.8);
    // opacity: 0;

    // transition-duration: 350ms;

    // &.active {
    //     background-color: rgba(0,0,0,0.5);
    //     opacity: 1;
    // }

    // &:hover, &:active{
    //     background-color: rgba(0,0,0,0.5);
    //     opacity: 1;
    // }
}

//  .overlay {
//      svg {
//          stroke: white;
//          @apply h-6 w-6;
//      }
//  }

//  .overlay {
//     z-index: 10;
//     background-color: rgba(0,0,0,0);
//     position: absolute;
//     opacity: 0;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     cursor: pointer;

//     transition-duration: 350ms;

//     &:hover, &:active{
//         background-color: rgba(0,0,0,0.5);
//         opacity: 1;
//     }
//  }
 
 .button-group {
     background-color: rgba(0,0,0,0.8);

     .button {
         svg {
             @apply h-14 w-14 lg:h-20 lg:w-20;
             stroke: white;

             &.small {
                 @apply h-6 w-6;
             }
         }
     }
 }


.v-enter-active,
.v-leave-active {
  transition: opacity 0.35s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>