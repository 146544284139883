<template>
  <div class="video-wrapper h-full">
    <div id="video"></div>
  </div>
</template>

<script>
export default {
    props: ['videoId'],
    data() {
        return {
            player: null
        }
    },
    mounted() {
      this.initYoutube()
    },
    methods: {
        initYoutube() {
            this.player = new window.YT.Player('video', {
                width: "100%",
                height: "100%",
                videoId: this.videoId,
                playerVars: { 'autoplay': 1, 'controls': 1, 'mute': 0}
            });
        }
    },
}
</script>

<style lang="scss" scoped>
//  .video-wrapper {
//   width: 100%;
//   padding-top: 56.25%; 
//   position: relative;

//      iframe {
//          position: absolute;
//          top: 0;
//          left: 0;
//          width: 100%;
//          height: 100%;
//      }
//  }

.video-wrapper {
    iframe {
        @apply aspect-video;
    }
}
</style>