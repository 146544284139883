<template>
  <div class="lg:h-screen flex flex-col items-center justify-center bg-yellow-300">
    <AppControls />
    <div class="flex flex-col md:flex-row h-full w-full">
      <FeedGrid />
      <RedditLive />
    </div>
    <SocialShare />
    <WatchModal />
    <HelpModal />
    <LoadingModal />
  </div>
</template>

<script>
import FeedGrid from "./components/FeedGrid.vue";
import RedditLive from "./components/RedditLive.vue";
import WatchModal from "./components/Watch/Modal.vue";
import SocialShare from "./components/SocialShare.vue";
import AppControls from "./components/AppControls/Menu.vue";
import HelpModal from "./components/Help/Modal.vue";
import LoadingModal from "./components/Loading.vue";

export default {
  name: "App",
  created() {
      var tag = document.createElement('script');

      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  },
  components: {
    FeedGrid,
    WatchModal,
    SocialShare,
    RedditLive,
    AppControls,
    HelpModal,
    LoadingModal
  },
};
</script>

<style>
@import "~gleap/build/index.min.css";
</style>
