<template>
<div v-if="showModal" class="modal fixed z-50 top-0 h-screen w-screen flex justify-center items-center" @click="close">
    <div @click.stop="" class="bg-blue-500 w-96 text-yellow-200 rounded-lg p-8">
        <!-- component -->
    <div class="flex items-center max-w-md mx-auto bg-white rounded-lg " x-data="{ search: '' }">
        <div class="w-full">
            <input type="search" v-model="url" v-on:keyup.enter="changeChannel" class="w-full px-4 py-1 text-gray-800 rounded-full focus:outline-none"
                placeholder="Youtube URL" x-model="search">
        </div>
        <div>
        <button type="submit" @click="changeChannel" class="flex items-center bg-yellow-300 justify-center w-12 h-12 text-black rounded-r-lg">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </button>
        </div>
    </div>
    <div v-if="error" class="bg-red-100 border border-red-400 mt-4 text-red-700 px-4 py-3 rounded relative" role="alert">
        <span class="block sm:inline">Must use only Youtube URLs (for now). Sorry :(</span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            url: null,
            error: false,
            showModal: false,
            videoIndex: null
        }
    },
    mounted() {
        this.emitter.on('open-channel-input', (value) => {
            this.showModal = true,
            this.videoIndex = value
        })
    },
    methods: {
        close() {
            this.showModal = false;
            this.url = null;
            this.error = false
        },
        changeChannel() {
            this.error = false;
            let id = this.getYoutubeVideoId()

            if (!id) {
                this.error = true;
                return;
            }

            let feed = {
                title: 'Custom Channel',
                url: this.url,
                id
            }
            
            this.emitter.emit('change-channel', {feed, index: this.videoIndex})
            this.showModal = false;
        },
        getYoutubeVideoId()
        {
            let url = this.url;
            let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            let match = url.match(regExp);
            return (match&&match[7].length==11)? match[7] : false;
        }
    }
}
</script>

<style>

</style>