<template>
    <div class="grid p-4 w-full grid-cols-3">
        <div class="col-start-1">
            <svg @click="help" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="#005BBB" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        </div>
        <div class="app-controls relative col-start-2 inline-block mx-auto">
            <div>
                <svg @click="toggle" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="#005BBB">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                </svg>
            </div>
            <div v-if="active" class="absolute text-center text-md font-semibold flex justify-center left-1/2 transform -translate-x-1/2 mt-2 bg-yellow-200 z-50 p-2 rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div>
                    <VideoGrid />
                    <RedditLive />
                    <Reset />
                </div>
            </div>
        </div>
        <div v-if="reddit" class="md:hidden col-start-3 ml-auto" @click="goto('reddit-live')">
             <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="#005BBB" viewBox="0 0 24 24"><path d="M14.558 15.827c.097.096.097.253 0 .349-.531.529-1.365.786-2.549.786l-.009-.002-.009.002c-1.185 0-2.018-.257-2.549-.786-.097-.096-.097-.253 0-.349.096-.096.254-.096.351 0 .433.431 1.152.641 2.199.641l.009.002.009-.002c1.046 0 1.765-.21 2.199-.641.095-.097.252-.097.349 0zm-.126-3.814c-.581 0-1.054.471-1.054 1.05 0 .579.473 1.049 1.054 1.049.581 0 1.054-.471 1.054-1.049 0-.579-.473-1.05-1.054-1.05zm-3.806 1.05c0-.579-.473-1.05-1.054-1.05-.581 0-1.055.471-1.055 1.05 0 .579.473 1.049 1.055 1.049.581.001 1.054-.47 1.054-1.049zm13.374-8.063v14c0 2.761-2.238 5-5 5h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5zm-4 6.853c0-.972-.795-1.764-1.772-1.764-.477 0-.908.191-1.227.497-1.207-.794-2.84-1.299-4.647-1.364l.989-3.113 2.677.628-.004.039c0 .795.65 1.442 1.449 1.442.798 0 1.448-.647 1.448-1.442 0-.795-.65-1.442-1.448-1.442-.613 0-1.136.383-1.347.919l-2.886-.676c-.126-.031-.254.042-.293.166l-1.103 3.471c-1.892.023-3.606.532-4.867 1.35-.316-.292-.736-.474-1.2-.474-.975-.001-1.769.79-1.769 1.763 0 .647.355 1.207.878 1.514-.034.188-.057.378-.057.572 0 2.607 3.206 4.728 7.146 4.728 3.941 0 7.146-2.121 7.146-4.728 0-.183-.019-.362-.05-.54.555-.299.937-.876.937-1.546z"/></svg>
        </div>
    </div>
</template>

<script>
import RedditLive from './RedditLive.vue'
import VideoGrid from './VideoGrid.vue'
import Reset from './Reset.vue'

export default {
    data() {
        return {
            active: false,
            reddit: false
        }
    },
    mounted() {
        this.emitter.on('toggle-reddit-panel', value => this.reddit = value)
        if (localStorage.redditLive) {
            this.reddit = (localStorage.redditLive == "true")
        }
    },
    methods: {
        toggle() {
            this.active = !this.active
        },
        goto(id) {
            document.getElementById(id).scrollIntoView({behavior: "smooth"});
        },
        help() {
            this.emitter.emit('open-help');
        }
    },
    components: {
        RedditLive,
        VideoGrid,
        Reset
    }
}
</script>