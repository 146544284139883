<template>
<div v-if="showModal" class="modal bg-yellow-300 fixed z-50 top-0 h-screen w-screen flex justify-center py-8 items-center">
    <div class="h-56 w-56">
      <svg class="animate-spin -ml-1 mr-3 h-56 w-56 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#005BBB" stroke-width="4"></circle>
        <path class="opacity-75" fill="#005BBB" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>

        <!-- <button type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
        </button> -->
    </div>
</div>
</template>

<script>

export default {
    data() {
        return {
            showModal: false,
        }
    },
    mounted() {
        this.getsome();
    },methods: {
        async getsome() {
            try {
                const res = await fetch('https://api.infofeed.live/feeds');
                const data = await res.json();
    
              console.log('Fetched', data)

            } catch (err) {
                console.log(err)
            }
        }
    }
}
</script>

<style lang="scss">
    .modal {
        // background-color: ;
    }
</style>