<template>
<div v-if="showModal" class="modal fixed z-50 top-0 h-screen w-screen flex justify-center py-8 items-center" @click="close">
    <div @click.stop="" class="bg-yellow-300 rounded-lg p-4 w-4/5 my-5 max-w-3xl text-lg max-h-full overflow-y-auto">
        <h3 class="font-semibold">What Even is this?</h3>
        <p class="py-2">I built this app to be able to make it easier to watch multiple youtube news and CCTV feeds. Wanted to to share it with everyone. Here are some of the current features.</p>

        <p class="pb-2">Hover or Tap on a video to bring up the UI</p>
        <img class="rounded-lg mx-auto w-full" src="@/assets/help/InfoFeed-Help-Overlay.png" />

        <ol class="py-2 pl-4 list-decimal">
            <li>Click the play icon to play or pause the video</li>
            <li>Click the eye icon to maximize the video and get full video controls</li>
            <li>Click the monitor to change the feed from a predefined list</li>
            <li>Click the plus to add your own live or regular YouTube url</li>
            <li>Click the sound icon to unmute/mute a video in the grid</li>
        </ol>

        <p class="pt-8 pb-2">Click the Settings Icon at the top to Customize your Panel</p>
        <img class="rounded-lg mx-auto w-full" src="@/assets/help/InfoFeed-Help-Menu.png" />
        <ol class="py-2 pl-4 list-decimal">
            <li>Choose how many videos you want to see on your screen at once</li>
            <li>Toggle the Reddit Live feed</li>
            <li>Click Reset to return to the default settings</li>
        </ol>

        Any settings you change will save to your browser. 
    </div>
</div>
</template>

<script>

export default {
    data() {
        return {
            showModal: false,
        }
    },
    mounted() {
        this.emitter.on('open-help', () => {
            this.showModal = true;
        })
    },
    methods: {
        close() {
            this.showModal = false;
        }
    }
}
</script>

<style lang="scss">
    .modal {
        background-color: rgba(0,0,0,0.75);
    }
</style>