<template>
  <div @click="reset" class="mt-4 cursor-pointer">RESET</div>
</template>

<script>
export default {
    methods: {
        reset() {
            this.emitter.emit('settings-reset')
        }
    }
}
</script>

<style>

</style>