<template>
<div v-if="showModal" class="modal fixed z-50 top-0 h-screen w-screen flex justify-center items-center" @click="close">
    <div @click.stop="" class="bg-blue-500 flex gap-4 text-yellow-200 rounded-lg p-4">
        <div>
            <h3 class="text-center font-bold underline">CCTV</h3>
            <ul class="channel-select">
                <li @click="changeChannel(feed)" v-for="feed in FeedData.filter(f => f.type === 'CCTV')" v-bind:class="{selected: isSelected(feed.id)}" :key="feed.id" class="flex gap-2 justify-center">
                    <svg v-if="isActive(feed.id)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                    {{ feed.name }}
                </li>
            </ul>
        </div>
        <div>
            <h3 class="text-center font-bold underline">News</h3>
            <ul class="channel-select text-center">
                <li @click="changeChannel(feed)" v-for="feed in FeedData.filter(f => f.type === 'News')" v-bind:class="{selected: isSelected(feed.id)}" :key="feed.id" class="flex gap-2 justify-center">
                    <svg v-if="isActive(feed.id)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                    {{ feed.name }}
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
import FeedData from "@/assets/feeds.json";

export default {
    props: ['feeds'],
    data() {
        return {
            showModal: false,
            videoId: null,
            videoIndex: null,
            FeedData
        }
    },
    mounted() {
        this.emitter.on('open-channel-select', (value) => {
            this.showModal = true;
            this.videoId = value.id;
            this.videoIndex = value.index;
        })
    },
    methods: {
        close() {
            this.showModal = false;
        },
        isSelected(id) {
            if(this.videoId == id) {
                return true
            }

            return false
        },
        isActive(id) {
            return this.feeds.some( el => el.id == id)
        },
        changeChannel(feed) {
            this.emitter.emit('change-channel', {feed, index: this.videoIndex})
            this.showModal = false;
        }
    },
    components: {
    }
}
</script>

<style lang="scss">
    .modal {
        background-color: rgba(0,0,0,0.75);
    }

    .channel-select {
        li {
            @apply p-1 font-medium;
            cursor: pointer;

            &.selected {
                @apply bg-blue-400;
            }

            &:hover {
                @apply bg-blue-400;
            }
        }
    }
</style>